<template>
  <div class="mitgliedschaft">

    <v-container class="component-container-purple" >
      <v-container class="component-container">
        <v-row v-if="mitgliedschaft.feena_und_timo_file">
          <v-col cols="12" >
            <span class="text-big">Feena & Timo über die Idee der</span><br />
            <span class="bigarrow">→</span><span class="amtsschrift text-bigger"> Genossenschaft</span>
          </v-col>
          <v-col cols="12">
            <MediaFileV2 :file="mitgliedschaft.feena_und_timo_file" :poster="mitgliedschaft.feena_und_timo_file_poster" contentWidth="804"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container  class="amtsinhaberwerden component-container">
      <v-row v-if="mitgliedschaft.amtsinhaber_in_werden_text">
        <v-col cols="12" class="wysiwyg">
          <div v-html="mitgliedschaft.amtsinhaber_in_werden_text" />
        </v-col>
        <v-col cols="12">
          <strong class="text-purple">Es gibt zwei Arten von Mitgliedschaft in der Genossenschaft:</strong>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn rounded href="#nutzendeMitglieder" :small="$vuetify.breakpoint.smAndDown" class="naa-button-purple" style="width: 70%">Nutzende Mitglieder <span class="arrow">→</span></v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn rounded href="#investierendeMitglieder" :small="$vuetify.breakpoint.smAndDown" class="naa-button-purple" style="width: 90%">Investierende Fördermitglieder <span class="arrow">→</span></v-btn>
        </v-col>
      </v-row>
    </v-container>
    
    <v-container class="component-container-purple"  id="nutzendeMitglieder">
      <v-container class="component-container">
        <v-row class="offer">
          <v-col v-if="mitgliedschaft.nutzende_mitglieder_text" cols="12">
            <div class="wysiwyg">
              <div v-html="mitgliedschaft.nutzende_mitglieder_text" />
            </div>
            
            <strong>Deine Option ist nicht dabei?</strong>
            <br />
            <br />
            <p style="padding-left: 30px">
              Lass uns über deine individuelle Coworking-Lösung sprechen.
              <br />
              <br />
              <v-btn :small="$vuetify.breakpoint.smAndDown" rounded to="/kontakt" class="naa-button-beige">Schreib uns an <span class="arrow">→</span></v-btn>
            </p>
          </v-col>
          <!--<v-col cols="12" sm="6">
            <OfferV2 :content="mitgliedschaft.nutzende_mitglieder_offers" />
          </v-col>-->
          <v-col cols="12" sm="4" v-for="(offer, i) in mitgliedschaft.nutzende_mitglieder_offers" :key="i">
            <SingleOfferV2 :content="offer" />
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="component-container my-6" id="investierendeMitglieder">
      <v-row v-if="mitgliedschaft.investierende_foerdermitglieder_left_text" class="gallery-text">
        <v-col cols="12" md="6" class="smaller-text wysiwyg-left">
          <div v-html="mitgliedschaft.investierende_foerdermitglieder_left_text" />
        </v-col>
        <v-col cols="12" md="6" class="smaller-text roundedpurple">
          <MediaFileV2 :file="mitgliedschaft.investierende_foerdermitglieder_image" contentWidth="370" />
          
          <div class="wysiwyg">
            <div v-html="mitgliedschaft.investierende_foerdermitglieder_right_text" />
          </div>
          
          <v-btn :small="$vuetify.breakpoint.smAndDown" rounded to="/kontakt" class="naa-button-beige">Schreib uns an <span class="arrow">→</span></v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container">
      <v-row v-if="mitgliedschaft.investierende_foerdermitglieder_files">
        <v-col cols="12">
          <MediaSwiperV2 :content="mitgliedschaft.investierende_foerdermitglieder_files" contentWidth="876"/>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'MitgliedschaftV2',
  data: () => ({
    mitgliedschaft: {}
  }),
  async created() {
    let temp = await this.$client.items("mitgliedschaft").readMany({ fields: ['*.*', 'investierende_foerdermitglieder_files.directus_files_id.*', 'nutzende_mitglieder_offers.offers_id.*.*.*'] })

    this.mitgliedschaft = temp.data
  }
}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .mitgliedschaft {

    .amtsinhaberwerden {

      .v-btn {
        background-color: $purple;
        color: $beige;
      }
    }
  }

  .gallery-text {
    background-color: $beige !important;
    color: black;

    .roundedpurple {
      background-color: $purple;
      color: $beige;
      border-radius: 40px;
    }

    .smaller-text {
      font-size: $font-size-1-small;
      padding: 40px;
    }
  }

</style>