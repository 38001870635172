<template>
  <div class="haus">

    <v-container class="component-container">
      <v-row v-if="haus.mehr_als_text">
        <v-col cols="12" sm="6" class="wysiwyg">
          <div v-html="haus.mehr_als_text" />
        </v-col>
        <v-col cols="12" sm="6">
          <MediaFileV2 :file="haus.mehr_als_image" contentWidth="426"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container-purple" >
      <v-container class="component-container">
        <v-row v-if="haus.nachhaltig_text">
          <v-col cols="12" class="wysiwyg-left">
            <div v-html="haus.nachhaltig_text" />
          </v-col>
          <v-col cols="12">
            <MediaFileV2 :file="haus.nachhaltig_file" :poster="haus.nachhaltig_file_poster" contentWidth="876"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="component-container">
      <v-row v-if="haus.das_gebaude_top_text">
        <v-col cols="12">
          <span class="text-purple headline">Das Gebäude.</span>
          <span class="amtsschrift text">&nbsp;&nbsp;Ökologisch wertvoll.</span>
        </v-col>
        <v-col cols="12" sm="6">
          <MediaSwiperV2 :content="haus.das_gebaude_files" contentWidth="426"/>
        </v-col>
        <v-col cols="12" sm="6" class="wysiwyg-left">
          <div v-html="haus.das_gebaude_top_text"/>
        </v-col>
        <v-col cols="12" class="wysiwyg">
          <div v-html="haus.das_gebaude_middle_text" />
        </v-col>
        <v-col cols="12" sm="6" class="wysiwyg-left">
          <div v-html="haus.das_gebaude_bottom_text" />
        </v-col>
        <v-col cols="12" sm="6">
          <MediaFileV2 :file="haus.das_gebaude_bottom_file" contentWidth="426"/>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container">    
      <span class="amtsschrift headline">Das Neue Amt</span><br />
      <span class="text-purple text">&nbsp;&nbsp;im Überblick</span>
      <PanelV2 :content="haus.ueberblick" />
    </v-container>

    <v-container class="component-container">
      <v-row>
        <v-col cols="12" sm="6" order="2" order-sm="1" style="-webkit-filter: grayscale(100%); filter: grayscale(100%);">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d996.5862330852798!2d9.937145255485184!3d53.55250335863098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1858875c0aa53%3A0x2d382115337c3309!2sGro%C3%9Fe%20Bergstra%C3%9Fe%20264%2C%2022767%20Hamburg!5e0!3m2!1sde!2sde!4v1617880317665!5m2!1sde!2sde" width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </v-col>
        <v-col cols="12" sm="6" order="1" order-sm="2" style="margin: auto">
          <span class="amtsschrift headline">Die lage</span><br />
          <span class="text-purple text">&nbsp;&nbsp;Mitten drin.</span>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'HausV2',
  data: () => ({
    haus: {}
  }),
  async created() {
    let temp = await this.$client.items("haus").readMany({ fields: ['*.*', 'das_gebaude_files.directus_files_id.*'] })
    this.haus = temp.data
  }
}
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .haus {
    .headline {
      font-size: $font-size-1-large;
      font-weight:bold;
    }

    .text {
      font-size: $font-size-1-large;
    }
  }

  </style>