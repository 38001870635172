<template>
  <div class="kontakt">
    <v-container class="component-container">
      <h2 class="amtsschrift">Schreib uns!</h2>
      <p class="text-big">
        Wir melden uns so schnell wie möglich bei Dir zurück.
      </p>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="6">
            <v-text-field v-model="firstname" color="#5d24b5" label="Vorname" :rules="[rules.required]" maxlength="40" outlined background-color="white" dense/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="lastname" color="#5d24b5" label="Nachname" :rules="[rules.required]" maxlength="40" outlined background-color="white" dense/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="email" color="#5d24b5" label="E-Mail" :rules="[rules.required, rules.email]" maxlength="40" outlined background-color="white" dense/>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="phone" color="#5d24b5" label="Telefon" maxlength="40" outlined background-color="white" dense/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="website" color="#5d24b5" label="Website" placeholder="http://beispiel.de" maxlength="80" outlined background-color="white" dense/>
          </v-col>
          <v-col cols="12">
            <v-textarea v-model="message" color="#5d24b5" label="Nachricht" placeholder="Optional: Bitte erzähle uns ein bisschen von dir. Was machst du beruflich und wie groß ist dein Team?" outlined background-color="white" auto-grow dense></v-textarea>
          </v-col>
          <v-col cols="12" v-if="nextEvent">
            <v-checkbox v-model="eventRegistration" color="#5d24b5" :label="nextEventLabel " :value="'event-' + new Date(nextEvent.datetime).toLocaleDateString('de-DE', {hour: '2-digit', minute:'2-digit'}) + '-ja'"/>
          </v-col>
          <v-col md="6" sm="12">
            <b class="text-purple">Ich interessiere mich für:</b> <br/>
            <v-radio-group v-model="interest" :rules="[rules.required]">
              <v-radio color="#5d24b5" label="einen festen Arbeitsplatz" value="einen festen Arbeitsplatz"/>
              <v-radio color="#5d24b5" label="ein Büro" value="ein Büro"/>
              <v-radio color="#5d24b5" label="einen flexiblen Arbeitsplatz" value="einen flexiblen Arbeitsplatz"/>
              <v-radio color="#5d24b5" label="eine investierende Mitgliedschaft" value="eine investierende Mitgliedschaft" />
              <v-radio color="#5d24b5" label="Sonstiges" value="Sonstiges" />
            </v-radio-group>
          </v-col>
          <v-col md="6" sm="12">
            <b class="text-purple">Ich habe vom Neuen Amt erfahren über:</b> <br/>
            <v-radio-group v-model="learnedAbout">
              <v-radio color="#5d24b5" label="Freunde oder Bekannte" value="Freunde oder Bekannte"/>
              <v-radio color="#5d24b5" label="Instagram" value="Instagram"/>
              <v-radio color="#5d24b5" label="LinkedIn" value="LinkedIn"/>
              <v-radio color="#5d24b5" label="Plakate" value="Plakate"/>
              <v-radio color="#5d24b5" label="Flyer" value="Flyer" />
              <v-radio color="#5d24b5" label="Medien" value="Medien" />
              <v-radio color="#5d24b5" label="NAA Pop-Up Space" value="NAA Pop-Up Space" />
              <v-radio color="#5d24b5" label="Events" value="Events" />
              <v-radio color="#5d24b5" label="Sonstiges" value="Sonstiges" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col cols="12">
            <v-alert v-if="processing" :type="processingAlertType">{{processingText}}</v-alert>
          </v-col>
          <v-col md="4" sm="12">
            <v-btn rounded :small="$vuetify.breakpoint.smAndDown" class="naa-button-purple" @click="sendMessage()">abschicken <span class="arrow">→</span></v-btn>
          </v-col>
        </v-row>
        <v-row class="presse">
          <v-col cols="12" sm="4" class="py-10 pl-10">
            <v-avatar size="240">
              <img v-if="kontakt.image" :src="imageLink(kontakt.image, 240)" :title="kontakt.image.title" />
            </v-avatar>
          </v-col>
          <v-col cols="12" sm="8" class="wysiwyg">
            <div v-html="kontakt.text" />
          </v-col>
          <v-col cols="12" style="text-align:center" v-if="kontakt.file">
            <v-btn outlined :small="$vuetify.breakpoint.smAndDown" rounded target="_blank" :href="'https://directus.neuesamt.org/assets/' + kontakt.file.filename_disk+'?download'" class="naa-button-purple" style="width:200px;">Pressekit<v-icon small style="position: absolute; right:0;">mdi-download</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>

import axios from "axios"

export default {
  name: 'KontaktV2',
  data: () => ({
    kontakt: {},
    nextEvent: null,
    processing: false,
    processingAlertType: 'info',
    processingText: 'Nachricht wird verschickt, einen Moment Geduld bitte ...',
    firstname: null,
    lastname:'',
    email:'',
    phone:'',
    website:'',
    eventRegistration:[],
    interest: '',
    learnedAbout:'',
    message:'',
    rules:{
      required: value => {
        return !!value || 'Feld wird benötigt'
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-Mail ungültig'
      },
    }
  }),
  async created() {
    let temp = await this.$client.items("events").readMany({ fields: ['*.*'], filter: {datetime: {'_gte': '$NOW'}}, limit: 1, sort:['datetime']})
    this.nextEvent = temp.data[0]

    temp = await this.$client.items("kontakt").readMany({ fields: ['*.*','coverage.coverage_id.*.*','moin_files.directus_files_id.*','staff.people_id.*.*'] })
    this.kontakt = temp.data
  },
  computed: {
    nextEventLabel: function () {
      if (!this.nextEvent) return '';

      let temp = new Date(this.nextEvent.datetime)

      return 'Ich möchte mich zur Veranstaltung am ' + temp.toLocaleDateString('de-DE', {hour: '2-digit', minute:'2-digit'}) + ' Uhr anmelden.'
    }
  },
  methods: {
      sendMessage: async function () {

        if (!this.$refs.form.validate() || this.processing) return null

        this.processing = true
        this.processingAlertType = 'info'
        this.processingText = 'Nachricht wird verschickt, einen Moment Geduld bitte ...'

        var sendMessage = axios.create( {baseURL: 'https://us-central1-neuesamt.cloudfunctions.net/app'});

        await sendMessage.post('/sendMessage',this.$data).then((result) => {

          this.processingAlertType = 'success'
          this.processingText = 'Nachricht erfolgreich verschickt!'

          setTimeout(() => {
            this.processing = false
            this.$refs.form.reset();
            this.$emit('close')
          }, 3000)
          
        }).catch((error)=>
        {console.log(error)})

      },
      imageLink: function (file, width) {
        if (file) {
          let temp = 'https://directus.neuesamt.org/assets/' + file.id + '?quality=75'

          if (width) temp += '&width=' + width
          
          return temp
        }

        return null
      }
    }
}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .kontakt {
    .presse {
      border: solid 3px $purple;
      border-radius: 30px;
    }
  }

</style>