<template>
  <div class="partner">

    <v-container class="component-container">
      <v-row v-if="partner.investierende_mitgliedschaften_text">
        <v-col cols="12" class="wysiwyg-colum">
          <div v-html="partner.investierende_mitgliedschaften_text" />
        </v-col>
        <v-btn :small="$vuetify.breakpoint.smAndDown" to="/mitgliedschaft#investierendeMitglieder" rounded class="naa-button-purple" style="width:90%">Werde Teil der NAA Förderer <span class="arrow">→</span></v-btn>
      </v-row>
    </v-container>

    <v-container class="component-container-purple" >
      <v-container class="component-container pb-0">
        <v-row v-if="partner.kooperation_partner_text" >
          <v-col cols="12" class="wysiwyg">
            <div v-html="partner.kooperation_partner_text" />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    
    <!--
    <v-container class="component-container">
      <CooperationsV2 :content="partner.cooperations" />
    </v-container>
    -->
    
    <v-container class="component-container-purple" >
      <v-container class="component-container pt-0">
        <PeopleV2 :content="partner.beirat" styleclass="purple"/>
      </v-container>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'PartnerV2',
  data: () => ({
    partner: {}
  }),
  async created() {
    let temp = await this.$client.items("partner").readMany({ fields: ['*.*', 'beirat.people_id.*.*', 'cooperations.cooperations_id.*.*'] })
    this.partner = temp.data
  }
}
</script>
