<template>
  <div class="vision">

    <v-container class="component-container">
      <v-row v-if="vision.wem_gehoert_text">
        <v-col cols="12">
          <MediaSwiperV2 :content="vision.wem_gehoert_files" contentWidth="876"/>
        </v-col>
        <v-col cols="12" class="wysiwyg">
          <div v-html="vision.wem_gehoert_text" />
        </v-col>
      </v-row>
    </v-container>
    
    <v-container class="component-container-purple" >
      <v-container class="component-container pa-0" style="margin: 0px auto 0px auto">
        <v-row>
          <v-col cols="12" class="">
            <span class="amtsschrift text-bigger">Nimm dies, Gentrifizierung:</span><br />
            <span class="text-big">Altbau bewahren und durch Neubau ergänzen</span>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container class="component-container">
      <v-row v-if="vision.gentrifizierung_text" class="text">
        <v-col cols="12" class="wysiwyg">
          <div v-html="vision.gentrifizierung_text" />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container-purple" >
      <v-container class="component-container pa-0" style="margin: 0px auto 0px auto">
        <v-row>
          <v-col cols="12">
            <span class="text-big">Die neue Stadtproduktion</span>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container class="component-container">
      <v-row v-if="vision.stadtproduktion_text" class="text">
        <v-col cols="12" class="wysiwyg">
          <div v-html="vision.stadtproduktion_text" />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="component-container-purple" >
      <v-container class="component-container">
        <v-row>
          <v-col cols="12">
            <MediaFileV2 :file="vision.stadtproduktion_file" contentWidth="876"/>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    
    <v-container class="component-container">
      <span class="text-purple-bigger text-bold">Der Weg in<br /> die Neue Amtszeit:</span>
      <PanelV2 :content="vision.neue_amtszeit"/>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'VisionV2',
  data: () => ({
    vision: {}
  }),
  async created() {
    let temp = await this.$client.items("vision").readMany({ fields: ['*.*.*'] })

    this.vision = temp.data
  }
}
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .text {
    background-color: $beige !important;
    color: black;

    .headline {
      background-color: $purple !important;
      color: $beige;
      font-size: $font-size-1-normal !important;

      p {
        margin: 0
      }
    }

    ul {
      
      margin-left: 30px;
      
      li {
        list-style-type: "-";
        padding-left: 10px;
      }

      li::marker {
        color: $purple;
        font-size: $font-size-1-normal;
      }
    }
  }
    
  .text-big-bold {
    font-size: $font-size-2-normal; 
    font-weight:bold
  }


</style>