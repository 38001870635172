<template>
  <div class="impressum">

    <v-container class="component-container">
      <v-row v-if="impressum.impressum">
        <v-col cols="12" class="wysiwyg">
          <div v-html="impressum.impressum" />
        </v-col>
        <v-col cols="12" class="wysiwyg">
          <div v-html="impressum.datenschutz" />
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'ImpressumV2',
  data: () => ({
    impressum: []
  }),
  async created() {
    let temp = await this.$client.items("impressum").readMany({ fields: ['*.*'] })

    this.impressum = temp.data
  }
}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .impressum {
    h1 {
      font-size: $font-size-2-normal;
    }

    h2 {
      font-size: $font-size-1-medium;
    }
  }


</style>