<template>
  <div class="FAQ">

    <v-container class="component-container">
      <v-row>
        <v-col cols="12"  class="header">
          <div class="amtsschrift">Du hast Fragen?</div>
          <span class="text-purple text-bold">Wir haben Antworten!</span>Falls doch eine Frage unbeantwortet bleibt, melde dich gerne bei uns: 
          <br />
          <v-btn rounded to="/kontakt" :small="$vuetify.breakpoint.smAndDown" class="naa-button-purple" style="margin: 16px 0 16px 0">Schreib uns an <span class="arrow">→</span></v-btn>
        </v-col>
        <v-col cols="12">
          <PanelV2 :content="faq"/>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'FAQV2',
  data: () => ({
    faq: {}
  }),
  async created() {
    let temp = await this.$client.items("faq").readMany({ fields: ['*.*'] })
    this.faq = temp.data
  }
}
</script>

<style lang="scss">

  @import "@/assets/_variables.scss";

  .FAQ {
    .header {
      font-size: $font-size-2-normal;
    }
  }
  
</style>