<template>
  <div class="community">
    
    <v-container class="component-container">
      <v-row v-if="community.wir_bauen_left_text">
        <v-col cols="12" sm="6"  class="wysiwyg" >
          <div v-html="community.wir_bauen_left_text" />
        </v-col>
        <v-col cols="12" sm="6">
          <MediaFileV2 :file="community.wir_bauen_file" :poster="community.wir_bauen_file_poster" contentWidth="426"/>
        </v-col>
        <v-col cols="12" class="wysiwyg">
          <div v-html="community.wir_bauen_bottom_text" />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="component-container-purple" >
      <v-container class="component-container">
        <PeopleV2 :content="community.euer_coworking_people" styleclass="purple"/>
        <div class="headline">
            Euer Co<br />
            <span id="changingWord" class="amtsschrift">cReAtinG</span><br />
            Space.
        </div>
      </v-container>
    </v-container>

    <v-container v-if="community.management_files" class="component-container">
      <v-row>
        <v-col cols="12" class="amtsschrift text-bigger">Community-management</v-col>
        <v-col cols="12" sm="6">
          <MediaSwiperV2 :content="community.management_files" contentWidth="428"/>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="wysiwyg">
            <div v-html="community.management_text"/>
          </div>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

export default {
  name: 'CommunityV2',
  data: () => ({
    community: {},
    changingWordItems: ['cReAtinG', 'crafting', 'exploring', 'thinking', 'editing', 'doing', 'making', 'building', 'developing', 'designing', 'producing', 'planning'],
  }),
  async created() {
    let temp = await this.$client.items("community").readMany({ fields: ['*.*', 'euer_coworking_people.people_id.*.*', 'management_files.directus_files_id.*'] })

    this.community = temp.data

    this.interval = setInterval(
      () => {
          if(document.getElementById("changingWord")) {
            document.getElementById("changingWord").innerHTML = (this.changingWordItems[Math.floor(Math.random() * this.changingWordItems.length)] )
          }
      }, 1000);
  }
}
</script>

<style lang="scss">
  
  @import "@/assets/_variables.scss";

  .community {
    .headline {
      font-size: $font-size-4-normal !important;
      line-height: unset;
      text-align: center;
    }
  }

  @media screen and (max-width : 959px) {
    .community {
      .headline {
        font-size: $font-size-2-normal !important;
        line-height: unset;
        text-align: center;
      }
    }
  }


</style>